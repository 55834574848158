import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { User } from 'src/app/shared/models/user.model';
// import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-component',
  templateUrl: './component.component.html',
  styleUrls: ['./component.component.scss']
})
export class ComponentComponent implements OnInit {
  userSession : User;

  constructor(private authService: AuthService) { }

  ngOnInit() {
    //getting current user
    this.authService.user.subscribe(data => {
      this.userSession = data; debugger;
    });

    // console.log(this.userSession);
  }

  //if page reloaded, current user data will be saved to session storage and get back in auth guard
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    sessionStorage.setItem("user", JSON.stringify(this.userSession));
  }

  // logout(){
  //   this.router.navigate(['/login']);
  // }

}

import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { User } from "../models/user.model";
import { AuthService } from "../services/auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  userSession: User;
  constructor(private authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    var urlArray = req.url.split("/");

    //getting current user
    this.authService.user.subscribe((data) => {
      this.userSession = data;
    });

    // const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sb2NhbGhvc3Q6ODAwMFwvYXBpXC9sb2dpbiIsImlhdCI6MTU3MTU0Njk5OCwiZXhwIjoxNTcxNTUwNTk4LCJuYmYiOjE1NzE1NDY5OTgsImp0aSI6IjNNZlNrY0lBdjhVcEI4TUIiLCJzdWIiOjIsInBydiI6Ijg3ZTBhZjFlZjlmZDE1ODEyZmRlYzk3MTUzYTE0ZTBiMDQ3NTQ2YWEifQ.rsm9TnrMrkoMwYpTORIgV_4V4N3y7Dd8dIMaRsYZAq4"; // you probably want to store it in localStorage or something
    const token = this.userSession.token;

    if (!token) {
      return next.handle(req);
    }

    const req1 = req.clone({
      headers: req.headers.set("Authorization", `Bearer ${token}`),
    });

    return next.handle(req1);
  }
}

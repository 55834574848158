import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ComponentComponent } from "./component.component";
import { AuthGuard } from "src/app/shared/core/auth-guard.service";

const routes: Routes = [
  {
    path: "",
    component: ComponentComponent,
    canActivate: [AuthGuard],
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ComponentRoutingModule {}

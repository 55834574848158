import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { LoginRoutingModule } from "./login-routing.module";
import { LoginComponent } from "./login.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FieldsetModule } from "primeng/fieldset";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { DialogModule } from "primeng/dialog";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";

@NgModule({
  imports: [
    CommonModule,
    LoginRoutingModule,
    FormsModule,
    MessagesModule,
    MessageModule,
    ReactiveFormsModule,
    FieldsetModule,
    ProgressSpinnerModule,
    DialogModule,
  ],
  declarations: [LoginComponent],
})
export class LoginModule {}
